.h4 {
  color: white;
  padding-right: 10px;
}

.announcement {
  position: absolute;
  text-align: center;
  padding-top: 70px;
  font-size: 12pt;
  color: red;
}

.navbar {
  /*background: linear-gradient(35deg, #4b007e 0%, #2d004d 100%);*/
  background-image: url('../../images/space.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-bottom-left-radius: 40px;
  /* position: sticky; */
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  max-width: 1500px;
  z-index: 30;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-top: -5px;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.navbar-logo img {
  height: 70px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(11, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 45vw;
  justify-content: start;
}

.nav-item {
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  /*transition: all 0.3s ease-out;*/
  border-radius: 8px;
  background: linear-gradient(to bottom, #353535, #282828);
}

.active {
  color: #25b4ff;
  text-shadow: 0 0 8px #000;
  border-radius: 8px;
  background: linear-gradient(to bottom, #353535, #282828);
}

.nav-links-no-active {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  background: transparent;
}

.nav-links-no-active:hover {
  border-radius: 8px;
  background: linear-gradient(to bottom, #353535, #282828);
}

.nav-link-launchpad {
  color: white;
  border-color: white;
  border-style: solid;
  border-size: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-link-launchpad:hover {
  background-color: #2d004d;
  transition: all 0.3s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  align-items: center;
  z-index: 10000;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 8px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.form-inline button {
  padding: 5px 10px;
  /*background-color: dodgerblue;*/
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.form-inline button:hover {
  background-color: royalblue;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;
  }

  .nav-menu.active {
    background: #16141c;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: 100%;
  }

  .nav-links,
  .nav-links-no-active {
    visibility: hidden;
  }

  .nav-links:hover {
    background: linear-gradient(90deg, #3f2b92 0%, #6d4aff 100%);
    color: white;
    border-radius: 0;
  }

  .nav-link-launchpad {
    visibility: hidden;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 45%);
  }

  .navbar-logo img {
    height: 60px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 90%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin-top: -10rem ;
    margin-left: 3rem;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 150ms;
  }
}
