.dropdown-menu {
  background: dimgray;
  width: 135px;
  position: absolute;
  top: 74px;
  margin-right: 20px;
  list-style: none;
  text-align: center;
  transform: translate(-10%, -8%);
  border-radius: 8px;
  z-index: 200;
}

.dropdown-menu li {
  cursor: pointer;
  border-radius: 8px;
}

.dropdown-menu li:hover {
  color: cyan;
  background: linear-gradient(to bottom, #353535, #282828);
  transition: all 0.3s ease-out;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 40px;
  width: 100%;
  text-decoration: none;
  padding: 10px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
