:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  width: max-content;
  margin-right: 5px;
  margin-left: 5px;
}

.btn--primary {
  background-color: darkslateblue;
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background: transparent;
  color: #fff;
  padding: 8px 15px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

.btn--medium {
  padding: 8px 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.btn--large {
  padding: 12px 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: linear-gradient(to bottom, #353535, #282828);
  color: #25b4ff;
  transition: 250ms;
}
