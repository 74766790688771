.footer {
  background: transparent;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
  color: white;
}

.footer-maintext {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
}

.footer-link {
  display: inline-block;
  font-size: small;
}

@media screen and (max-width: 960px) {
  .footer {
    justify-content: center;
    align-items: center;
  }

  .footer p {
    margin-right: 0;
  }
}
